import React from 'react';
import { Link, navigate } from 'gatsby';

function LanguageSelector({ lang, location, isDisabled }) {
	if (lang === 'en') {
		return (
			<button
				className={`px-2 md:px-4 ${isDisabled ? '' : 'hover:text-gray-400'}`}
				disabled={isDisabled}
				onClick={() => navigate(`/bg${location.pathname}`)}
			>
				БГ
			</button>
		);
	} else {
		return (
			<button
				className={`px-2 md:px-4 ${isDisabled ? '' : 'hover:text-gray-400'}`}
				disabled={isDisabled}
				onClick={() =>
					navigate(location.pathname.replace('/' + lang + '/', '/'))
				}
			>
				EN
			</button>
		);
	}
}

export default LanguageSelector;
