/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';

import Header from './header';
import Footer from './footer';
import './layout.css';

const Layout = ({ children, location, lang, isDisabled }) => {
	return (
		<div className="">
			{' '}
			{/* set className="dark" to turn on Dark Mode  */}
			<Header location={location} lang={lang} isDisabled={isDisabled} />
			<main className="dark:bg-black">{children}</main>
			<Footer location={location} lang={lang} />
		</div>
	);
};

export default Layout;
