import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import LanguageSelector from './LanguageSelector';

const Header = ({ lang, location = null, isDisabled }) => {
  return (
    <header className="sticky top-0 bg-black z-40">
      <div
        className="flex flex-col sm:flex-row justify-between 
    items-center mx-auto py-4 px-8 text-white"
      >
        <Link to={lang === 'en' ? `/` : `/${lang}/`}>
          <div className="flex w-12">
            <StaticImage src="../images/logo.png" alt="Atelier Motif - Logo" />
          </div>
        </Link>
        <div className="flex mt-4 text-xs sm:mt-0 md:text-sm font-semibold">
          <Link
            to={lang === 'en' ? `/projects` : `/${lang}/projects`}
            className="px-2 md:px-4 hover:text-gray-400"
          >
            {lang === 'en' ? `PROJECTS` : `ПРОЕКТИ`}
          </Link>
          <Link
            to={lang === 'en' ? `/art` : `/${lang}/art`}
            className="px-2 md:px-4 hover:text-gray-400"
          >
            {lang === 'en' ? `ART` : `АРТ`}
          </Link>
          <Link
            to={lang === 'en' ? `/about` : `/${lang}/about`}
            className="px-2 md:px-4 hover:text-gray-400"
          >
            {lang === 'en' ? `ABOUT` : `ЗА НАС`}
          </Link>
          <Link
            to={lang === 'en' ? `/contact` : `/${lang}/contact`}
            className="px-2 md:px-4 hover:text-gray-400"
          >
            {lang === 'en' ? `CONTACT` : `КОНТАКТИ`}
          </Link>
          <LanguageSelector
            isDisabled={isDisabled}
            location={location}
            lang={lang}
          />
        </div>
      </div>
    </header>
  );
};
export default Header;
