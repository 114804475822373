import * as React from 'react';
import { Link } from 'gatsby';

const Footer = ({ lang, location = null }) => {
	return (
		<footer className="bg-black text-gray-50 px-8 lg:px-8 py-4 pt-12">
			<div className="max-w-screen-xl mx-auto ">
				<div className="grid grid-cols-8 md:grid-cols-9  lg:grid-cols-8  divide-gray-200 divide-y-2 md:divide-x-2 md:divide-y-0 md:-mx-8">
					<div className="col-span-8 md:col-span-3 lg:col-span-2 md:px-8 py-4 md:py-0">
						<h5 className="text-xl font-semibold text-white">
							{lang === 'en' ? `Links` : `Линкове`}
						</h5>
						<nav className="mt-4">
							<ul className="space-y-2 ml-0">
								<li>
									<Link
										to={lang === 'en' ? `/about` : `/${lang}/about`}
										className="font-normal text-base hover:text-gray-400"
									>
										{lang === 'en' ? `About` : `За нас`}
									</Link>
								</li>
								<li>
									<Link
										to="/"
										className="font-normal text-base hover:text-gray-400"
									>
										{lang === 'en' ? `Privacy Policy` : `Условия`}
									</Link>
								</li>
							</ul>
						</nav>
					</div>
					<div className="col-span-8 md:col-span-3 lg:col-span-3 md:px-8 py-4 md:py-0">
						<h5 className="text-xl font-semibold text-white">
							{lang === 'en' ? `Contact` : `Контакти`}
						</h5>
						<nav className="mt-4">
							<ul className="grid ml-0">
								<li className="mb-2">
									<a
										href="mailto:atelier@atelier-motif.com"
										className="flex space-x-2  font-normal text-base hover:text-gray-400"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6"
											aria-hidden="true"
											viewBox="0 0 20 20"
											fill="currentColor"
										>
											<path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
											<path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
										</svg>
										<span>atelier@atelier-motif.com</span>
									</a>
								</li>
								<li className="mb-2">
									<a
										href="tel:+359886587186"
										className="flex space-x-2  font-normal text-base hover:text-gray-400"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6"
											aria-hidden="true"
											viewBox="0 0 20 20"
											fill="currentColor"
										>
											<path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
										</svg>
										<span>+359 886 587 186</span>
									</a>
								</li>
							</ul>
						</nav>
					</div>
					<div className="col-span-8 md:col-span-3 lg:col-span-3 md:px-8 py-4 md:py-0">
						<h5 className="text-xl font-semibold text-white">
							{lang === 'en' ? `Follow us` : `Социални Мрежи`}
						</h5>
						<nav className="mt-4">
							<ul className="grid ml-0">
								<li className="mb-2">
									<a
										href="/"
										className="flex space-x-2  font-normal text-base hover:text-gray-400"
									>
										<svg
											className="h-6 w-6"
											aria-hidden="true"
											fill="currentColor"
											viewBox="0 0 24 24"
										>
											<path
												fillRule="evenodd"
												d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
												clipRule="evenodd"
											/>
										</svg>
										<span>Instagram</span>
									</a>
								</li>
								<li className="mb-2">
									<a
										href="/"
										className="flex space-x-2  font-normal text-base hover:text-gray-400"
									>
										<svg
											className="h-6 w-6"
											aria-hidden="true"
											fill="currentColor"
											viewBox="0 0 24 24"
										>
											<path
												fillRule="evenodd"
												d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
												clipRule="evenodd"
											/>
										</svg>
										<span>Facebook</span>
									</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</div>
			<div className="max-w-screen-xl mx-auto flex flex-col md:flex-row justify-between space-y-0 mt-8 border-t-2 border-gray-200 pt-8">
				<nav className="flex flex-wrap justify-center space-x-6">
					<p className="font-normal text-sm">
						&copy;2021 Atelier Motif. All rights reserved.
					</p>
				</nav>
				<a
					href="https://www.integralwebdesigns.com/"
					target="_blank"
					rel="noopener noreferrer"
					className="text-sm text-center md:text-right hover:text-gray-400"
				>
					Created by Integral Web Designs
				</a>
			</div>
		</footer>
	);
};
export default Footer;
